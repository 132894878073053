import React from 'react'
import { Row, Col } from 'antd'
import ComplementYourProgram from '../images/ComplementYourProgram.jpg'

const ToComplement = () => {
    return (
        <Row justify="center" className="to-complement">
            <Col lg={14} className="to-complement__content">
                <Row>
                    <Col lg={24}>
                        <h3>Haz un UPGRADE a la gestión de salud de tus colaboradores con estos servicios complementarios</h3>
                    </Col>
                    <Col lg={24}>
                        <ul>
                            <li>
                                <p>Telemedicina: completo servicio que incluye atención en medicina general, nutrición y psicología</p>
                            </li>
                            <li>
                                <p>Screening de salud: ayuda a tu equipo a tomar conciencia con datos concretos y desde una base personal</p>
                            </li>
                            <li>
                                <p>Seguimiento de casos: identificación y monitoreo de grupos de riesgo</p>
                            </li>
                            <li>
                                <p>Seguros complementarios de salud para tu equipo y seguros de vida individual</p>
                            </li>
                            <li>
                                <p>Actividades y cursos: cuéntanos qué necesitas y desarrollamos tu idea junto a nuestro grupo de expertos</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Col>
            <Col lg={9} xs={24} className="to-complement__image">
                <Row justify="center">
                    <Col lg={24} xs={24} className="image-wrapper" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <img src={ComplementYourProgram} alt="Aplicación pantallas" width="400" height="453" />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ToComplement