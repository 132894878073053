import React from 'react'
import { Row, Col, Card } from 'antd'
import Card1 from '../images/Card1.svg'
import Card2 from '../images/Card2.svg'
import Card3 from '../images/Card3.svg'
import Card4 from '../images/Card4.svg'
import Card5 from '../images/Card5.svg'
import Card6 from '../images/Card6.svg'

const Improvements = () => {
   return (
      <Row justify="center">
            <Col lg={20} xs={22} className="improvements-wrapper__cards">
               <Row gutter={[20, 20]}>
                  <Col lg={4} xs={12}>
                     <Card>
                        <Row justify="center">
                           <Col lg={24}style={{display: 'flex' ,justifyContent: 'center', alignItems: 'center', height: 100}}>
                                 <img src={Card1} />
                           </Col>
                           <Col lg={24} xs={22}>
                                 <p>Promueve los hábitos saludables</p>
                           </Col>
                        </Row>
                     </Card>
                  </Col>
                  <Col lg={4} xs={12}>
                     <Card>
                        <Row justify="center">
                           <Col lg={24}style={{display: 'flex' ,justifyContent: 'center', alignItems: 'center', height: 100}}>
                                 <img src={Card2} />
                           </Col>
                           <Col lg={24} xs={22}>
                                 <p>Desarrolla cultura de bienestar</p>
                           </Col>
                        </Row>
                     </Card>
                  </Col>
                  <Col lg={4} xs={12}>
                     <Card>
                        <Row justify="center">
                           <Col lg={24}style={{display: 'flex' ,justifyContent: 'center', alignItems: 'center', height: 100}}>
                                 <img src={Card3} />
                           </Col>
                           <Col lg={24} xs={21}>
                                 <p>Reduce costos de salud</p>
                           </Col>
                        </Row>
                     </Card>
                  </Col>
                  <Col lg={4} xs={12}>
                     <Card>
                        <Row justify="center">
                           <Col lg={24}style={{display: 'flex' ,justifyContent: 'center', alignItems: 'center', height: 100}}>
                                 <img src={Card4} />
                           </Col>
                           <Col lg={23} xs={21}>
                                 <p>Mejora la productividad</p>
                           </Col>
                        </Row>
                     </Card>
                  </Col>
                  <Col lg={4} xs={12}>
                     <Card>
                        <Row justify="center">
                           <Col lg={24}style={{display: 'flex' ,justifyContent: 'center', alignItems: 'center', height: 100}}>
                                 <img src={Card5} />
                           </Col>
                           <Col lg={24} xs={22}>
                                 <p>Disminuye el ausentismo</p>
                           </Col>
                        </Row>
                     </Card>
                  </Col>
                  <Col lg={4} xs={12}>
                     <Card>
                        <Row justify="center">
                           <Col lg={24} style={{display: 'flex' ,justifyContent: 'center', alignItems: 'center', height: 100}}>
                                 <img src={Card6} />
                           </Col>
                           <Col lg={24} xs={22}>
                                 <p>Aumenta el compromiso</p>
                           </Col>
                        </Row>
                     </Card>
                  </Col>
               </Row>
            </Col>
            <Col lg={18} xs={22} className="imporvements-wrapper__circles">
               <Row justify="space-between" gutter={[20, 20]}>
                  <Col lg={7} xs={13} className="circle-wrapper" style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                     <Row justify="center">
                        <Col lg={17} xs={19} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                           <h3>12%</h3>
                        </Col>
                        <Col lg={17} xs={19}>
                           <p>Reducción de sedentarismo</p>
                        </Col>
                     </Row>
                  </Col>
                  <Col lg={7} xs={13} className="circle-wrapper" style={{ display: 'flex', justifyContent: 'center', marginBottom: 20}}>
                     <Row justify="center">
                        <Col lg={17} xs={19} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                           <h3>16%</h3>
                        </Col>
                        <Col lg={17} xs={19}>
                           <p>Sube su nivel de actividad física</p>
                        </Col>
                     </Row>
                  </Col>
                  <Col lg={7} xs={13} className="circle-wrapper" style={{ display: 'flex', justifyContent: 'center', marginBottom: 20}}>
                     <Row justify="center">
                        <Col lg={17} xs={19} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                           <h3>73%</h3>
                        </Col>
                        <Col lg={17} xs={19}>
                           <p>Le encantan los desafíos y actividades</p>
                        </Col>
                     </Row>
                  </Col>
               </Row>
               <Row justify="start">
                  <Col lg={18} xs={18}>
                     <h4>*Encuesta NPS luego de un año de implementación de programa</h4>
                  </Col>
               </Row>
            </Col>
      </Row>
   )
}

export default Improvements