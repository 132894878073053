import React from 'react'
import { Row, Col } from 'antd'
import Marquee from 'react-marquee-slider'
import * as _ from  'lodash'

const CompaniesFidelity = () => {
    return (
        <Row justify="center">
            <Col lg={24} xs={24}>
                <h3>Han confiado en nosotros</h3>
            </Col>
            <Col lg={24} xs={24} className="fidelity__marquee" style={{ height: 120 }}>
                <Marquee key={2} velocity={15} minScale={13}>
                    {_.map(_.range(1, 7), (value, key) => (
                        <div style={{ marginLeft: 20, marginRight: 20 }} key={key}>
                            <img src={`../../companiesLogos/${value}.png`} height="80" />
                        </div>
                    ))}
                </Marquee>
            </Col>
        </Row>
    )
}

export default CompaniesFidelity