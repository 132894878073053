import React from 'react'
import LevelLine from './images/LevelLine'

const Levels = () => {
  return (
    <>
      <div className="companies-landing__levels__marketing-text">
        <img src={require('../images/superate.svg')} />
      </div>
      <div className="companies-landing__levels__line">
        <LevelLine />
      </div>
      <div className="companies-landing__levels__icons">
        <div className="companies-landing__levels__icons__sleep">
          <img src={require('../images/level_sleep.svg')} />
        </div>
        <div className="companies-landing__levels__text__sleep">
          <h2>Perezoso</h2>
          <div>0 puntos</div>
          <div className="companies-landing__levels__level__sleep">
            <div>Nivel 0</div>
          </div>
        </div>
        <div className="companies-landing__levels__icons__sedentary">
          <img src={require('../images/level_sedentary.svg')} />
        </div>
        <div className="companies-landing__levels__text__sedentary">
          <h2>Tortuga</h2>
          <div>De 1 a 60 puntos</div>
          <div className="companies-landing__levels__level__sedentary">
            <div>Nivel 1</div>
          </div>
        </div>
        <div className="companies-landing__levels__icons__low">
          <img src={require('../images/level_low.svg')} />
        </div>
        <div className="companies-landing__levels__text__low">
          <h2>Conejo</h2>
          <div>De 60 a 95 puntos</div>
          <div className="companies-landing__levels__level__low">
            <div>Nivel 2</div>
          </div>
        </div>
        <div className="companies-landing__levels__icons__somewhat">
          <img src={require('../images/level_somewhat.svg')} />
        </div>
        <div className="companies-landing__levels__text__somewhat">
          <h2>Caballo</h2>
          <div>De 96 a 130 puntos</div>
          <div className="companies-landing__levels__level__somewhat">
            <div>Nivel 3</div>
          </div>
        </div>
        <div className="companies-landing__levels__icons__active">
          <img src={require('../images/level_active.svg')} />
        </div>
        <div className="companies-landing__levels__text__active">
          <h2>Cheetah</h2>
          <div>De 131 a 165 puntos</div>
          <div className="companies-landing__levels__level__active">
            <div>Nivel 4</div>
          </div>
        </div>
        <div className="companies-landing__levels__icons__highly">
          <img src={require('../images/level_highly.svg')} />
        </div>
        <div className="companies-landing__levels__text__highly">
          <h2>Halcon</h2>
          <div>De 166 a 500 puntos</div>
          <div className="companies-landing__levels__level__highly">
            <div>Nivel 5</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Levels;