import React from 'react'

const LevelLine = () => {
  return (
    <div>
      <div className="mobile-line">
        <img src={require('../../images/mobile_line.svg')} />
      </div>
      <div className="desktop-line">
        <img src={require('../../images/desktop_line.svg')} />
      </div>
    </div>
  )
}

export default LevelLine;