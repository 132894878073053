import React from 'react'
import { Row, Col } from 'antd'
import { InstagramFilled, FacebookFilled, LinkedinFilled } from "@ant-design/icons"
import DegradedForm from '../images/DegradedForm.svg'
import HubspotForm from 'react-hubspot-form'

const CompanyContact = () => {
    const phoneNumber = "+56936116482" // Replace with your WhatsApp number
    const message = "Hola, tengo una duda." // Replace with your desired message
    const whatsapp_url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`
    return (
        <Row justify="center">
            <Col lg={24} xs={24} className="contact__title">
                <h3>¿Listo para una mejor calidad de vida y ambiente laboral? <br></br> ¡Conversemos!</h3>
            </Col>
            <Col lg={24} xs={24} className="contact__form-wrapper">
                <Row justify="center" align="middle">
                    <Col lg={{ order: 1, span: 10 }} xs={{ order: 2, span: 22 }}>
                        <Row justify="center" className="contact-wrapper">
                            <Col lg={24} sm={13} xs={22}>
                                <Row justify="center" className="contact-wrapper__phone">
                                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></g></g></svg>
                                        </span>
                                    </Col>
                                    <Col>
                                        <a href={whatsapp_url} target="_blank">
                                            <p>+56936116482</p>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={24} sm={13} xs={22}>
                                <Row justify="center" className="contact-wrapper__mail">
                                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 773.93"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path d="M461.21,378.21c27.05,17.83,74.53,17.83,101.59,0h0L1024,74.38A111.73,111.73,0,0,0,918.47,0H105.53A111.73,111.73,0,0,0,0,74.38L461.19,378.2Z" /><path d="M595.5,435.13h0c-23.4,15.6-53.45,23.4-83.48,23.4s-60.07-7.8-83.48-23.41h0L0,149.46V663.6A110.45,110.45,0,0,0,110.33,773.93H913.67A110.45,110.45,0,0,0,1024,663.6V149.46Z" /></g></g></svg>
                                        </span>
                                    </Col>
                                    <Col>
                                        <a href="mailto:contacto@muvu.cl" target="_blank">
                                            <p>contacto@muvu.cl</p>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={24} sm={13} xs={22}>
                                <Row justify="center">
                                    <Col lg={19}>
                                        <p className="contact-wrapper__follow">Síguenos en nuestras redes sociales</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={24} xs={22}>
                                <Row justify="center" className="contact-wrapper__social">
                                    <Col lg={14}>
                                        <Row justify="center" gutter={20}>
                                            <Col>
                                                <a href="https://www.instagram.com/muvu.cl/" target="blank">
                                                    <InstagramFilled style={{ fontSize: '40px', color: '#FFFFFF' }} />
                                                </a>
                                            </Col>
                                            <Col>
                                                <a href="https://www.facebook.com/muvu.cl" target="_blank">
                                                    <FacebookFilled className="social-facebook" style={{ fontSize: '40px', color: '#FFFFFF' }} />
                                                </a>
                                            </Col>
                                            <Col>
                                                <a href="https://www.linkedin.com/company/muvu-app" target="blank">
                                                    <LinkedinFilled className="social-linkedin" style={{ fontSize: '40px', color: '#FFFFFF' }} />
                                                </a>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={{ order: 2, span: 14 }}
                        xs={{ order: 1, span: 22 }}
                        className="form-wrapper"
                        style={{
                            backgroundImage: `url('${DegradedForm}')`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'left -16vh',
                            width: '80%',
                            height: '90vh',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    >

                        <HubspotForm
                            portalId='7407576'
                            formId='de35f8c4-054d-40da-89ae-107fb9d8116d'
                            onSubmit={() => console.log('Submit!')}
                            onReady={(form) => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                            style={{ width: '100%' }}
                        />

                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default CompanyContact