import React from 'react'
import { Row, Col } from 'antd'
import ForYourCompany from '../images/ForYourCompany.jpg'

const ForCompany = () => {
    return (
        <Row justify="center" className="for-company">
            <Col xl={14} lg={12} xs={24} className="for-company__content">
                <Row>
                    <Col lg={24} xs={24}>
                        <ul>
                            <li>
                                <p>Personaliza la APP con la image de tu empresa</p>
                            </li>
                            <li>
                                <p>Customiza desafíos, mide actividad física, calorías quemadas, distancias, desafíos personales y por equipos</p>
                            </li>
                            <li>
                                <p>Customiza el catálog de beneficios y recompensas, e incentiva a tu equipo con lo que ellos realmente necesitan</p>
                            </li>
                            <li>
                                <p>Visibiliza todos tus beneficios actuales en la sección "Beneficios"</p>
                            </li>
                            <li>
                                <p>Entrega contenido relevante alineado a tu campaña de comunicación y educación. Agenda notificaciones y recordatorios</p>
                            </li>
                            <li>
                                <p>Vincula las iniciativas de RSE y motívalos a ayudar</p>
                            </li>
                            <li>
                                <p>Completo set de reportes y métricas, para gestionar tu programa de bienestar</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Col>
            <Col xl={9} lg={11} xs={24} className="for-company__image">
                <Row justify="center">
                    <Col lg={24} xs={24} className="image-wrapper" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <img src={ForYourCompany} alt="Aplicación pantallas" width="398" height="514" />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ForCompany

