import React, { useState } from 'react'
import { Link } from 'gatsby'
import '../css/empresas.scss'
import HomeLanding from '../images/HomeLanding.png'
import InstitutoInternacional from '../images/InstitutoInternacional.png'
import logoMuvuNavbar from '../images/logoMuvuNavbar.svg'
import Corfo from '../images/Corfo.png'
import { Row, Col, Button, Layout, Affix } from 'antd'
import Improvements from '../components/Improvements'
import CompanyBenefits from '../components/CompanyBenefits'
import CompaniesFidelity from '../components/CompaniesFidelity'
import Stores from '../components/Stores'
import CompanyContact from '../components/CompanyContact'
import Levels from '../components/Levels'
import SEO from "../components/seo"
import Presentation from '../components/Presentation'
import Info from '../components/Info'
import WhatsAppButton from "../components/WhatsAppButton"
import GoogleTagManager from "../components/GoogleTagManager";
import LinkedInAnalytics from "../components/LinkedInAnalytics";

const { Header, Content, Footer } = Layout

const CompaniesLanding = () => {
   const [isOpen, setOpen] = useState(false)

   const text = (
      <Row>
         <h1>Lleva el <strong style={{ fontWeight: 'bold' }}>bienestar y la salud de tu equipo</strong> al siguiente nivel</h1>
         <h2>Muvu es una aplicación de bienestar laboral, que utiliza el movimiento para motivar a tus colaboradores e impulsar el teambuilding. A través de distintas herramientas y actividades puedes motivar el cumplimiento de los objetivos de tu organización de forma lúdica y dinámica.<br></br><strong style={{ fontWeight: 'bold' }}>¡Imagina un equipo enérgico y eficiente!</strong></h2>
      </Row>
   )

   return (
      <React.Fragment>
         <SEO
            title="App de incentivos, salud y actividad física - Muvu Empresas"
            description="El primer programa de incentivos que enseña a tu equipo de salud, prevención, actividad física, nutrición y bienestar a cambio de recompensas."
         />
         <Info type="info-wrapper-companies" />
         <Affix offsetTop={0}>
            <Header className="header">
               <Row justify="center" className="header__main">
                  <Col lg={21} xs={22}>
                     <Row justify="space-between">
                        <Col>
                           <Link to="/" style={{ display: 'flex', alignItems: 'center', height: 56 }}>
                              <img src={logoMuvuNavbar} alt="Logo Muvu" />
                           </Link>
                        </Col>
                        <Col>
                           <Row justify="center">
                              <Col lg={24} xs={24} style={{ display: 'flex', alignItems: 'center', height: 56 }}>
                                 <Button className="main__button" href="#contact" block>Contáctanos</Button>
                              </Col>
                           </Row>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Header>
         </Affix>
         <Content style={{ zIndex: 1 }}>
            <Row justify="center" className="companies-landing__container">
               <Col lg={24}>
                  <Row>
                     <Col lg={24}>
                        <Presentation text={text} image={HomeLanding} />
                     </Col>
                  </Row>
                  <Row justify="center" className="companies-landing__purpose">
                     <Col lg={24} xs={22}>
                        <Row justify="center">
                           <Col xl={20} lg={20} xs={22}>
                              <h3>A través de nuestra APP, te ayudamos a desarrollar un programa de bienestar, con excelentes resultados</h3>
                           </Col>
                           <Col xl={20} lg={22} xs={24}>
                              <Row justify="start" gutter={20}>
                                 <Col lg={{ order: 2, span: 17 }} xs={{ order: 1, span: 24 }}>
                                    <p>Nuestro principal propósito es mejorar el bienestar laboral, y para eso la motivación es clave. <br></br>
                                    A través de técnicas lúdicas buscamos mejorar la retención de talento y fomentar equipos felices, productivos y comprometidos, utilizamos el movimiento para motivar a tus colaboradores a superar su categoría e impulsar el teambuilding,<br></br>
                                    desarrollando una cultura en torno al wellness y promoviendo un mejor ambiente laboral. </p>
                                 </Col>
                                 <Col lg={{ order: 2, span: 24 }} xs={{ order: 1, span: 24 }}>
                                    <div className="companies-landing__levels">
                                       <Levels />
                                    </div>
                                 </Col>
                              </Row>
                           </Col>
                        </Row>
                     </Col>
                  </Row>
                  <Row justify="center" className="companies-landing__improvements">
                     <Col lg={24} xs={24} className="improvements-wrapper">
                        <Improvements />
                     </Col>
                  </Row>
                  <Row justify="center" className="companies-landing__benefits">
                     <Col lg={24} xs={24}>
                        <h3>¡Lo hacemos dinámico y divertido!</h3>
                        <h4>Una plataforma, múltiples posibilidades</h4>
                     </Col>
                     <Col lg={24} xs={24}>
                        <CompanyBenefits />
                     </Col>
                  </Row>
                  <Row justify="center" className="companies-landing__stores">
                     <Stores />
                  </Row>
                  <Row justify="center" className="companies-landing__press">
                     <Col lg={24} xs={24}>
                        <h3>Prensa</h3>
                     </Col>
                     <Col lg={20} xs={22}>
                        <Row justify="center" gutter={[20, 50]}>
                           <Col lg={6} xs={24}>
                              <div style={{ textAlign: 'center' }}>
                                 <a target="_blank" href="https://www.24horas.cl/tendencias/ciencia-tecnologia/app-chilena-premia-con-descuentos-el-estar-en-movimiento-4649559">
                                    <img src={require('../images/24horas.png')} style={{ height: 60 }} />
                                 </a>
                              </div>
                           </Col>
                           <Col lg={6} xs={24}>
                              <div style={{ textAlign: 'center' }}>
                                 <a target="_blank" href="https://www.elmostrador.cl/generacion-m/2021/06/27/desarrollos-tecnologicos-que-ayudan-a-salir-del-sedentarismo/">
                                    <img src={require('../images/elmostrador.png')} style={{ height: 60 }} />
                                 </a>
                              </div>
                           </Col>
                           <Col lg={6} xs={24}>
                              <div style={{ textAlign: 'center' }}>
                                 <a target="_blank" href="https://www.publimetro.cl/cl/estilo-vida/2021/01/29/muvu-app-premia-movimiento.html">
                                    <img src={require('../images/publimetro.png')} style={{ height: 60 }} />
                                 </a>
                              </div>
                           </Col>
                           <Col lg={6} xs={24}>
                              <div style={{ textAlign: 'center' }}>
                                 <a target="_blank" href="http://www.hoyxhoy.cl/2021/04/19/full/cuerpo-principal/9/">
                                    <img src={require('../images/hoyxhoy.png')} style={{ height: 60 }} />
                                 </a>
                              </div>
                           </Col>
                           <Col lg={6} xs={24}>
                              <div style={{ textAlign: 'center' }}>
                                 <a target="_blank" href="https://www.youtube.com/watch?v=GuNErjTdaD0">
                                    <img src={require('../images/txs-plus.png')} style={{ height: 60 }} />
                                 </a>
                              </div>
                           </Col>
                           <Col lg={6} xs={24}>
                              <div style={{ textAlign: 'center' }}>
                                 <a target="_blank" href="https://cl.radiocut.fm/audiocut/muvu-en-academia-emprendedores-muro-oportunidades/?replay=11#evtCat=AudioCutDetail&evtAct=Progress&evtLabel=ExitReplay">
                                    <img src={require('../images/adn.png')} style={{ height: 60 }} />
                                 </a>
                              </div>
                           </Col>
                           <Col lg={6} xs={24}>
                              <div style={{ textAlign: 'center' }}>
                                 <a target="_blank" href="https://youtu.be/_6VEoMsUIY4?t=2436">
                                    <img src={require('../images/tvn.png')} style={{ height: 60 }} />
                                 </a>
                              </div>
                           </Col>
                           <Col lg={6} xs={24}>
                              <div style={{ textAlign: 'center' }}>
                                 <a target="_blank" href="https://www.msn.com/es-cl/noticias/otras/app-chilena-premia-con-descuentos-el-estar-en-movimiento/ar-BB1dADJm">
                                    <img src={require('../images/msn.png')} style={{ height: 60 }} />
                                 </a>
                              </div>
                           </Col>
                        </Row>
                     </Col>
                  </Row>
                  <Row justify="center" className="companies-landing__fidelity">
                     <Col lg={24}>
                        <CompaniesFidelity />
                     </Col>
                  </Row>
                  <Row justify="center" id="contact" className="companies-landing__contact">
                     <Col lg={24}>
                        <CompanyContact />
                     </Col>
                  </Row>
               </Col>
            </Row>
         </Content>
         <Footer className="footer-wrapper">
            <Row justify="center" className="footer-wrapper__institutes-support">
               <Col lg={15}>
                  <Row justify="center" gutter={[40, 40]}>
                     <Col lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={InstitutoInternacional} alt="Instituto internacional" width="300" height="73" />
                     </Col>
                     <Col lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={Corfo} alt="Corfo" width="236" height="84" />
                     </Col>
                  </Row>
               </Col>
            </Row>
         </Footer>
         <WhatsAppButton message="Hola quiero conocer muvu" />
         <GoogleTagManager />
         <LinkedInAnalytics />
      </React.Fragment>
   )
}

export default CompaniesLanding