import React from 'react'
import { Row, Col, Tabs, Collapse } from 'antd'
import { isMobile } from 'react-device-detect'
import ForCompany from '../components/ForCompany'
import ForTeam from '../components/ForTeam'
import ToComplement from '../components/ToComplement'

const { TabPane } = Tabs
const { Panel } = Collapse

const CompanyBenefits = () => {

   const onChangeTab = (value) => {
      console.log(value)
   }

   return (
      <Row justify="center">
            <Col xl={20} lg={22} xs={22}>
               {
                  !isMobile ? 
                     <Tabs 
                        defaultActiveKey={["1"]} 
                        type="card"
                        onChange={onChangeTab}
                        tabBarGutter={15}
                     >
                        <TabPane tab="Para tu empresa" key="1">
                           <ForCompany />
                        </TabPane>
                        <TabPane tab="Para tu equipo" key="2">
                           <ForTeam />
                        </TabPane>
                        <TabPane tab="Complementa tu programa" key="3">
                           <ToComplement />
                        </TabPane>
                     </Tabs>
                     :
                     <Collapse defaultActiveKey={["1"]}expandIconPosition="right" ghost bordered={false}>
                        <Panel header="Para tu empresa" key="1">
                           <ForCompany />
                        </Panel>
                        <Panel header="Para tu equipo" key="2">
                           <ForTeam />
                        </Panel>
                        <Panel header="Complementa tu programa" key="3">
                           <ToComplement />
                        </Panel>
                     </Collapse>
               }
            </Col>
      </Row>
   )
}

export default CompanyBenefits