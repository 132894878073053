import React from 'react'
import { Row, Col } from 'antd'
import ForYourTeam from '../images/ForYourTeam.jpg'

const ForTeam = () => {
    return (
        <Row justify="center" className="for-team">
            <Col xl={14} lg={13} xs={24} className="for-team__content">
                <Row>
                    <Col lg={24} xs={24}>
                        <ul>
                            <li>
                                <p>Conecta Muvu a tu aplicación de actividad física favorita y comienza a ganar puntos</p>
                            </li>
                            <li>
                                <p>Participa en entretenidos desafíos y compite con tus colegas</p>
                            </li>
                            <li>
                                <p>Aprende de bienestar a través de nuestras trivias y contenidos</p>
                            </li>
                            <li>
                                <p>Participa en un ranking por categoría y global junto a todo tu equipo</p>
                            </li>
                            <li>
                                <p>Desafíate y define una meta de actividad semanal para superar</p>
                            </li>
                            <li>
                                <p>Gana recompensas, premios y descuentos mientras te vuelves saludable</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Col>
            <Col xl={9} lg={10} xs={24} className="for-team__image">
                <Row justify="center">
                    <Col lg={24} xs={24} className="image-wrapper" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <img src={ForYourTeam} alt="Aplicación pantallas" width="393" height="390" />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ForTeam